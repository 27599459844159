import { t } from '../../../i18n'
import Role from '../../../models/Role'

interface ConfigSettingsOption {
  title: string
  subtitle: string
  link: string
  roles: Role[]
}

const configSettingsOptions: ConfigSettingsOption[] = [
  {
    title: t('Campagnes'),
    subtitle: t('Créez, activez et désactivez vos campagnes.'),
    link: '/settings/campaigns',
    roles: ['ROLE_TRADER'],
  },
  {
    title: t('Produits'),
    subtitle: t('Importez vos produits et paramétrez-les.'),
    link: '/settings/products',
    roles: ['ROLE_TRADER'],
  },
  {
    title: t('Lieux de destination'),
    subtitle: t('Créez, activez et désactivez vos lieux de destination.'),
    link: '/settings/delivery-sites',
    roles: ['ROLE_TRADER'],
  },
  {
    title: t('Secteurs'),
    subtitle: t("Gérez vos secteurs géographiques d'activité."),
    link: '/settings/sectors',
    roles: ['ROLE_TRADER'],
  },
  {
    title: t("Modalités d'exécution"),
    subtitle: t("Personnalisez vos modalités d'exécution."),
    link: '/settings/execution-modes',
    roles: ['ROLE_TRADER'],
  },
  {
    title: t('Tables de majorations'),
    subtitle: t(
      'Importez, éditez et gérez vos tables de majorations mensuelles.'
    ),
    link: '/settings/majoration-tables',
    roles: ['ROLE_TRADER', 'ROLE_SUPERADMIN'],
  },
  {
    title: t('Parités de transport'),
    subtitle: t(
      'Importez, éditez et gérez vos tables de parités de transport.'
    ),
    link: '/settings/parities',
    roles: ['ROLE_TRADER'],
  },
  {
    title: t('Frais de stockage'),
    subtitle: t('Importez, éditez et gérez vos tables de frais de stockage.'),
    link: '/settings/storages',
    roles: ['ROLE_TRADER'],
  },
  {
    title: t('Vente'),
    subtitle: t(
      'Personnalisez les paramètres des ventes accessibles aux clients.'
    ),
    link: '/settings/sales',
    roles: ['ROLE_TRADER'],
  },
  {
    title: t('Clients'),
    subtitle: t('Importez et gérez les clients du service (agriculteurs).'),
    link: '/settings/customers',
    roles: ['ROLE_TRADER'],
  },
  {
    title: t('Utilisateurs'),
    subtitle: t(
      'Créez et gérez les utilisateurs du service (hors agriculteurs).'
    ),
    link: '/settings/users',
    roles: ['ROLE_TRADER'],
  },
  {
    title: t('Apports'),
    subtitle: t('Importez les apports clients sur votre plateforme.'),
    link: '/settings/contributions',
    roles: ['ROLE_TRADER'],
  },
  {
    title: t('E-mail'),
    subtitle: t("Editez et gérez vos modèles d'e-mail."),
    link: '/settings/mail-templates',
    roles: ['ROLE_TRADER'],
  },
  {
    title: t('Contrats'),
    subtitle: t(
      'Editez et gérez vos confirmations de vente transmises à vos clients.'
    ),
    link: '/settings/contract-templates',
    roles: ['ROLE_TRADER', 'ROLE_SUPERADMIN'],
  },
  {
    title: t('Libellés des marchés'),
    subtitle: t('Gérez les libellés des marchés'),
    link: '/settings/market',
    roles: ['ROLE_SUPERADMIN'],
  },
  {
    title: t('Gestion des conseils'),
    subtitle: t(
      "Modifiez les textes d'aide des écrans de paramétrages pour vos utilisateurs."
    ),
    link: '/settings/advices',
    roles: ['ROLE_SUPERADMIN'],
  },
  {
    title: t('Gestion des templates de mail'),
    subtitle: t('Modifiez les templates de mail par défaut.'),
    link: '/settings/mail-templates',
    roles: ['ROLE_SUPERADMIN'],
  },
  {
    title: t("Libellés des modes d'éxécution"),
    subtitle: t("Gérez les libellés des modes d'éxécution"),
    link: '/settings/executionmodelabels',
    roles: ['ROLE_SUPERADMIN'],
  },
  {
    title: t('Négociants'),
    subtitle: t('Gérez les négociants'),
    link: '/settings/merchants',
    roles: ['ROLE_SUPERADMIN'],
  },
  {
    title: t('Profil'),
    subtitle: t('Gérez votre profil'),
    link: '/settings/profil',
    roles: ['ROLE_TRADER'],
  },
  {
    title: t('Strike PMG'),
    subtitle: t("Gérez les libellés des strike pour l'option PMG"),
    link: '/settings/strike-pmg',
    roles: ['ROLE_TRADER', 'ROLE_SUPERADMIN'],
  },
  {
    title: t('PMG'),
    subtitle: t('Paramétrer les jours et heures de marché'),
    link: '/settings/pmg',
    roles: ['ROLE_SUPERADMIN'],
  },
  {
    title: t('Exporter les contrats'),
    subtitle: t('Export les contrats pour Kerhis'),
    link: '/settings/exportcontracts',
    roles: ['ROLE_TRADER', 'ROLE_SUPERADMIN'],
  },
]

export default configSettingsOptions
