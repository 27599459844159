import axios from '../config/axios'
import Campaign from '../models/Campaign'
import Offer from '../models/Offer'
import OffersPricesView from '../models/OffersPricesView'
import Product from '../models/Product'
import ExecutionMode from '../models/ExecutionMode'
import StrikeSetting, { StrikeValue } from '../models/StrikeSetting'
import TargetOrder from '../models/TargetOrder'

export type OfferExecutionView = {
  offerId: number
  executionDate: string
  executionMode: ExecutionMode
  price?: number
  strike?: StrikeSetting
}

export const create = (offer: Offer, targetOrderIdsToRenew: number[] = []) =>
  axios.post<Offer>('api/offers', { offer, targetOrderIdsToRenew })

export const findAllActive = () => axios.get<Offer[]>('api/offers?active')

export const findAllActiveAtTime = (activeAt: Date) =>
  axios.get<Offer[]>('api/offers', { params: { activeAt } })

export const findById = (id: number) => axios.get<Offer>(`api/offers/${id}`)

export const close = (id: number) => axios.delete(`api/offers/${id}`)

export const findAllByCampaignAndProduct = (
  campaign: Campaign,
  product: Product
) =>
  axios.get<Offer[]>('api/offers', {
    params: { campaignId: campaign.id, productId: product.id },
  })

export const getLastPublishedForCampaignAndProduct = (
  campaign: Campaign,
  product: Product
) =>
  axios.get<Offer>('api/offers/last-published', {
    params: { campaignId: campaign.id, productId: product.id },
  })

export const getCalendarPrices = (
  offerIds: number[],
  startDate: Date,
  endDate: Date,
  strike?: StrikeValue,
  userId?: number
) => {
  const params = {
    offersIds: offerIds.join(','),
    startDate,
    endDate,
    strike,
    userId,
  }
  return axios.get<OffersPricesView>('api/offers/prices', {
    params,
    timeout: parseInt(process.env.REACT_APP_SLOW_API_TIMEOUT || '10000'),
  })
}

export const getCalendarWithoutPrices = (
  offerIds: number[],
  startDate: Date,
  endDate: Date,
  strike?: StrikeValue,
  queryExpired?: boolean
) =>
  axios.get<OffersPricesView>('api/offers/planning', {
    params: {
      offersIds: offerIds.join(','),
      startDate,
      endDate,
      strike,
      queryExpired,
    },
  })

export const getOfferExecutions = (
  offerIds: number[],
  startDate: Date,
  endDate: Date
) =>
  axios.get<OfferExecutionView[]>('api/offers/executions', {
    params: { offersIds: offerIds.join(','), startDate, endDate },
  })

export const getOfferExecutionsForCustomer = (
  userId: number,
  offerIds: number[],
  startDate: Date
) =>
  axios.get<OfferExecutionView[]>('api/offers/executions', {
    params: { offersIds: offerIds.join(','), startDate, userId },
  })

export const getNewOfferCalendarPrices = (
  offer: Offer,
  startDate: Date,
  endDate: Date,
  strike?: StrikeValue
) =>
  axios.post<OffersPricesView>(
    `api/offers/prices?startDate=${startDate}&endDate=${endDate}` +
      (strike ? `&strike=${strike}` : ''),
    offer,
    {
      timeout: parseInt(process.env.REACT_APP_SLOW_API_TIMEOUT || '10000'),
    }
  )

export const getRemainingOfferVolume = (
  campaign: Campaign,
  product: Product,
  executionMode: string,
  executionMonth: string
) =>
  axios.get<number>(`api/offers/remaining-volume`, {
    params: {
      campaignId: campaign.id,
      productId: product.id,
      executionMode,
      executionDate: executionMonth,
    },
  })

export const getRemainingOfferVolumeForCustomer = (
  campaign: Campaign,
  product: Product,
  executionMode: string,
  executionMonth: string,
  userId: number
) =>
  axios.get<number>(`api/offers/remaining-volume`, {
    params: {
      campaignId: campaign.id,
      productId: product.id,
      executionMode,
      executionDate: executionMonth,
      userId,
    },
  })

export const getExpiredTargetOrdersFromOfferToCreate = (offer: Offer) =>
  axios.post<TargetOrder[]>('api/offers/expired-target-orders', offer, {
    timeout: parseInt(process.env.REACT_APP_SLOW_API_TIMEOUT || '10000'),
  })

export default {
  findAllActive,
  findAllActiveAtTime,
  findById,
  create,
  getLastPublishedForCampaignAndProduct,
  getCalendarPrices,
  getCalendarWithoutPrices,
  getOfferExecutions,
  getOfferExecutionsForCustomer,
  getNewOfferCalendarPrices,
  findAllByCampaignAndProduct,
  getRemainingOfferVolume,
  getRemainingOfferVolumeForCustomer,
  close,
  getExpiredTargetOrdersFromOfferToCreate,
}
