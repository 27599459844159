import {
  Button,
  FormControl,
  FormLabel,
  Theme,
  CircularProgress,
} from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { InlineDateTimePicker } from 'material-ui-pickers'
import { MaterialUiPickersDate } from 'material-ui-pickers/typings/date'
import { Form } from 'formik'
import format from 'date-fns/format'
import { subDays } from 'date-fns'
import { t } from '../../../../i18n'

import ErrorContext from '../../../../contexts/ErrorContext'
import Flex from '../../../../components/Flex'
import transactionService from '../../../../services/transactionService'
import TraderSettingsConfigCard from '../TraderSettingsConfigCard'

const useStyles = makeStyles((theme: Theme) => ({
  cardContent: {
    paddingRight: 15,
    width: '100%',
  },
  select: {
    padding: 10 + '!important',
  },
  loading: {
    padding: 30,
  },
  table: {
    '& td:last-child': {
      paddingRight: 0,
    },
  },
  tableRow: {
    height: theme.spacing(5),
  },
  tableButtonCell: {
    paddingRight: 0,
  },
  headerLabel: {
    textTransform: 'uppercase',
    letterSpacing: '0.1rem',
  },
  modal: {
    minWidth: '400px',
  },
  modalContent: {
    paddingBottom: '4px',
  },
  formControl: {
    minWidth: 270,
  },
  exportButton: {
    marginTop: '10px',
  },
  formFullSize: {
    width: '100%',
  },
}))

export const TraderSettingsExportContracts = () => {
  const classes = useStyles()
  const errorContext = useContext(ErrorContext)
  const [startDate, setStartDate] = useState(subDays(new Date(), 1))
  const [endDate, setEndDate] = useState(new Date())
  const [downloadLoading, setDownloadLoading] = useState(false)

  const downloadTxtFile = (content: any) => {
    const element = document.createElement('a')
    const file = new Blob([content], { type: 'text/plain' })
    element.href = URL.createObjectURL(file)
    element.download = `CONTRAT_${format(new Date(), 'yyyy-MM-dd')}.csv`
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
  }

  const onExport = async () => {
    setDownloadLoading(true)
    try {
      let content = await transactionService.exportContracts({
        startDate,
        endDate,
      })
      downloadTxtFile(content.data)
    } catch (error) {
      errorContext.displayError("Erreur lors de l'export des contrats")
    }
    setDownloadLoading(false)
  }
  const handleStartDateChange = (date: MaterialUiPickersDate) => {
    setStartDate(date)
  }
  const handleEndDateChange = (date: MaterialUiPickersDate) => {
    setEndDate(date)
  }

  return (
    <TraderSettingsConfigCard
      cardTitle={'Exporter vos contrats'}
      adviceMessage="Cette page permet d'exporter les contrats en csv pour les envoyer dans Kehris"
    >
      <Form className={classes.formFullSize}>
        <Flex direction="row">
          <FormControl className={classes.formControl}>
            <FormLabel htmlFor="startDateFilter">{t('Start Date')}</FormLabel>
            <InlineDateTimePicker
              id="startDateFilter"
              name="startDate"
              value={startDate}
              onChange={handleStartDateChange}
              invalidDateMessage={t('Date invalide')}
              disableOpenOnEnter={true}
              keyboard={true}
              showTabs={false}
              ampm={false}
              autoOk={true}
              format={'dd/MM/yyyy HH:mm'}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <FormLabel htmlFor="endDateFilter">{t('End Date')}</FormLabel>
            <InlineDateTimePicker
              id="endDateFilter"
              name="endDate"
              value={endDate}
              onChange={handleEndDateChange}
              invalidDateMessage={t('Date invalide')}
              disableOpenOnEnter={true}
              keyboard={true}
              showTabs={false}
              ampm={false}
              autoOk={true}
              format={'dd/MM/yyyy HH:mm'}
            />
          </FormControl>
        </Flex>
        <Flex direction="row" className={classes.exportButton}>
          <Button onClick={() => onExport()} disabled={downloadLoading}>
            Export contrats
          </Button>
          {downloadLoading && <CircularProgress size={20} />}
        </Flex>
      </Form>
    </TraderSettingsConfigCard>
  )
}

export default TraderSettingsExportContracts
