import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton/IconButton'
import List from '@material-ui/core/List/List'
import ListItem from '@material-ui/core/ListItem/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'
import EditIcon from '@material-ui/icons/Create'
import PreviewIcon from '@material-ui/icons/VisibilityOutlined'
import * as React from 'react'

import Margin from '../../../../components/Margin'
import SnackbarsExtended from '../../../../components/SnackbarsExtended'
import { t } from '../../../../i18n'
import MailTemplate from '../../../../models/MailTemplate'
import Tags from '../../../../models/Tags'
import mailTemplateService from '../../../../services/mailTemplateService'

import TraderSettingsMailTemplatesDialog from './TraderSettingsMailTemplatesDialog'

const styles = (theme: Theme) =>
  createStyles({
    list: {
      width: '100%',
    },
    title: {
      marginLeft: theme.spacing(2),
    },
  })

interface Props extends WithStyles<typeof styles> {
  mailTemplates: MailTemplate[]
  refresh: () => void
}

interface State {
  open: boolean
  errorOpen: boolean
  currentMailTemplate?: MailTemplate
}

class TraderSettingsContractTemplatesList extends React.Component<
  Props,
  State
> {
  readonly state: State = {
    open: false,
    errorOpen: false,
  }

  handleClose = () => {
    this.setState({ open: false, currentMailTemplate: undefined })
  }

  handleSave = async (mailTemplate: MailTemplate) => {
    this.setState({ open: false, currentMailTemplate: undefined })
    await mailTemplateService.update(mailTemplate)
    this.props.refresh()
  }

  handleEdit = (mailTemplate: MailTemplate) => () => {
    this.setState({ open: true, currentMailTemplate: mailTemplate })
  }

  handlePreview = (mailTemplate: MailTemplate) => () => {
    const popup = window.open('', '_blank')
    if (popup) {
      popup.document.write(mailTemplate.template)
    }
  }

  handleErrorClose = () => () => {
    this.setState({ errorOpen: false })
  }

  getTitle = (type: MailTemplate['type']): string => {
    switch (type) {
      case 'SUBSCRIPTION_CONFIRMATION':
        return t("Confirmation d'inscription")
      case 'PASSWORD_RESET':
        return t('Réinitialisation du mot de passe')
      case 'SELL_CONFIRMATION':
        return t('Confirmation de vente')
      case 'ERROR_MESSAGE':
        return t("Message d'erreur")
      case 'TARGET_ORDER_CONFIRMATION':
        return t("Confirmation de vente à prix d'objectif")
      case 'TARGET_ORDER_EXPIRED':
        return t("Expiration d'un ordre de vente")
      case 'TARGET_ORDER_CANCELED':
        return t("Annulation d'un ordre de vente")
      case 'PMG_SELL_CONFIRMATION':
        return t("Confirmation d'une vente PMG")
      case 'PMG_SUPPLEMENT_CONFIRMATION':
        return t("Confirmation d'une vente PMG fixée")
      case 'SELL_TRADER_ERROR_MESSAGE':
        return t("Message d'erreur technique")
      case 'EXPORT_CONTRACTS':
        return t('Export des contrats')
      case 'TARGET_ORDER_RENEWAL_CONFIRMATION':
        return t(
          "Confirmation de renouvellement d'un ordre de vente à prix d'objectif"
        )
    }
  }

  getSubtitle = (type: MailTemplate['type']) => {
    switch (type) {
      case 'SUBSCRIPTION_CONFIRMATION':
        return t('Envoyé au client à son inscription')
      case 'PASSWORD_RESET':
        return t(
          'Envoyé au client à chaque demande de réinitialisation de mot de passe'
        )
      case 'TARGET_ORDER_CONFIRMATION':
      case 'SELL_CONFIRMATION':
        return t('Envoyé au client et son commercial à chaque vente effectuée')
      case 'ERROR_MESSAGE':
        return t(
          "Envoyé au client et son commercial à chaque erreur lors d'une vente"
        )
      case 'SELL_TRADER_ERROR_MESSAGE':
        return t(
          'Envoyé au trader à chaque erreur causant un saut de n° de contrat'
        )
      case 'TARGET_ORDER_EXPIRED':
        return t('Envoyé au client lorsque son ordre de vente expire')
      case 'TARGET_ORDER_CANCELED':
        return t('Envoyé au client lorsque son ordre de vente est annulé')
      case 'PMG_SELL_CONFIRMATION':
        return t(
          'Envoyé au client et son commercial à chaque vente PMG effectuée'
        )
      case 'PMG_SUPPLEMENT_CONFIRMATION':
        return t(
          "Envoyé au client et son commercial à chaque vente PMG effectuée lors d'une fixation"
        )
      case 'EXPORT_CONTRACTS':
        return t(
          "Envoyé au négociant tous les soir avec en copie l'export des contrats"
        )
      case 'TARGET_ORDER_RENEWAL_CONFIRMATION':
        return t(
          "Envoyé au client et son commercial lors du renouvellement d'un ordre de vente à prix d'objectif à la création d'une offre"
        )
    }
  }

  getTagsByMailType = (mailTemplateType?: MailTemplate['type']) => {
    switch (mailTemplateType) {
      case 'PASSWORD_RESET':
        return Tags.ResetPassword
      case 'SUBSCRIPTION_CONFIRMATION':
        return Tags.Registeration
      case 'ERROR_MESSAGE':
        return Tags.Error
      case 'SELL_CONFIRMATION':
      case 'PMG_SELL_CONFIRMATION':
      case 'PMG_SUPPLEMENT_CONFIRMATION':
        return Tags.TransactionMail
      case 'TARGET_ORDER_CONFIRMATION':
      case 'TARGET_ORDER_EXPIRED':
      case 'TARGET_ORDER_CANCELED':
        return Tags.TargetOrder
      case 'TARGET_ORDER_RENEWAL_CONFIRMATION':
        return Tags.TargetOrderRenewal
      case 'SELL_TRADER_ERROR_MESSAGE':
        return Tags.TraderError
      case 'EXPORT_CONTRACTS':
        return Tags.TraderExportContracts
      default:
        return []
    }
  }

  render() {
    const { classes, mailTemplates } = this.props
    const { open, currentMailTemplate } = this.state

    return (
      <Margin bottom={3}>
        <List className={classes.list}>
          {mailTemplates.map(mailTemplate => (
            <ListItem key={mailTemplate.id} role={undefined} dense button>
              <ListItemText
                primary={this.getTitle(mailTemplate.type)}
                secondary={this.getSubtitle(mailTemplate.type)}
              />
              <ListItemSecondaryAction>
                <IconButton
                  aria-label="Edit"
                  onClick={this.handleEdit(mailTemplate)}
                >
                  <EditIcon color="primary" />
                </IconButton>
                <IconButton
                  aria-label="Preview"
                  onClick={this.handlePreview(mailTemplate)}
                >
                  <PreviewIcon color="primary" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <TraderSettingsMailTemplatesDialog
          onClose={this.handleClose}
          mailTemplate={currentMailTemplate}
          open={open}
          onSave={this.handleSave}
          tags={this.getTagsByMailType(
            currentMailTemplate && currentMailTemplate.type
          )}
        />
        <SnackbarsExtended
          variant="error"
          open={this.state.errorOpen}
          onClose={this.handleErrorClose}
          message={t('Une erreur est survenue.')}
        />
      </Margin>
    )
  }
}

export default withStyles(styles)(TraderSettingsContractTemplatesList)
