import React, { useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import CustomerHome from '../scenes/customer/Home/CustomerHome'
import CustomerSale from '../scenes/customer/Sale/CustomerSale'
import CustomerSaleRecap from '../scenes/customer/Sale/CustomerSaleRecap'
import CustomerSalesList from '../scenes/customer/SalesList/CustomerSalesList'
import CustomerSettings from '../scenes/customer/Settings/CustomerSettings'
import Logout from '../scenes/Logout'
import NotFound from '../scenes/NotFound'
import SalesmanCustomerDetails from '../scenes/salesman/Customers/SalesmanCustomerDetails'
import SalesmanCustomers from '../scenes/salesman/Customers/SalesmanCustomers'
import SalesmanCustomerSale from '../scenes/salesman/Customers/SalesmanCustomerSale'
import SalesmanCustomerSaleRecap from '../scenes/salesman/Customers/SalesmanCustomerSaleRecap'
import SalesmanSalesList from '../scenes/salesman/SalesList/SalesmanSalesList'
import AdvicesSettings from '../scenes/superadmin/Settings/Advices/AdvicesSettings'
import MarketSettings from '../scenes/superadmin/Settings/Market/MarketSettings'
import SuperAdminSettings from '../scenes/superadmin/Settings/SuperAdminSettings'
import TraderCustomerDetails from '../scenes/trader/Customers/TraderCustomerDetails'
import TraderCustomers from '../scenes/trader/Customers/TraderCustomers'
import TraderCustomerSale from '../scenes/trader/Customers/TraderCustomerSale'
import TraderCustomerSaleRecap from '../scenes/trader/Customers/TraderCustomerSaleRecap'
import TraderHome from '../scenes/trader/Home/TraderHome'
import NewOfferRecap from '../scenes/trader/NewOffer/NewOfferRecap'
import NewOfferStep1 from '../scenes/trader/NewOffer/NewOfferStep1'
import NewOfferStep2 from '../scenes/trader/NewOffer/NewOfferStep2'
import TraderOffersList from '../scenes/trader/OffersList/TraderOffersList'
import TraderSalesList from '../scenes/trader/SalesList/TraderSalesList'
import TraderSettingsContractTemplates from '../scenes/trader/Settings/ContractTemplates/TraderSettingsContractTemplates'
import ContributionsSettings from '../scenes/trader/Settings/ContributionsSettings'
import TraderSettingsCustomers from '../scenes/trader/Settings/Customers/TraderSettingsCustomers'
import DeliverySitesSettings from '../scenes/trader/Settings/DeliverySitesSettings'
import ExecutionModesSettings from '../scenes/trader/Settings/ExecutionModesSettings'
import TraderSettingsMailTemplates from '../scenes/trader/Settings/MailTemplates/TraderSettingsMailTemplates'
import TraderSettingsMajorationTables from '../scenes/trader/Settings/MajorationTables/TraderSettingsMajorationTables'
import ProductsSettings from '../scenes/trader/Settings/ProductsSettings'
import SalesSettings from '../scenes/trader/Settings/SaleSettingsScene'
import SectorSettings from '../scenes/trader/Settings/SectorsSettings'
import StoragesSettings from '../scenes/trader/Settings/StoragesSettingsScene'
import TraderSettings from '../scenes/trader/Settings/TraderSettings'
import TraderSettingsCampaign from '../scenes/trader/Settings/TraderSettingsCampaign'
import TransportParitySettings from '../scenes/trader/Settings/TransportParitySettings'
import UsersSettings from '../scenes/trader/Settings/UsersSettings'
import TraderPmgSalesList from '../scenes/trader/PMG/SalesList/TraderPmgSalesList'
import SuperAdminSettingsMailtemplate from '../scenes/superadmin/Settings/SuperAdminSettingsMailTemplate'
import SuperAdminSettingsExecutionModeLabels from '../scenes/superadmin/Settings/ExecutionModeLabels/SuperAdminSettingsExecutionModeLabels'
import SuperAdminSettingsContractTemplates from '../scenes/superadmin/Settings/ContractTemplates/SuperAdminSettingsContractTemplates'
import CustomerTargetOrderDetail from '../scenes/customer/TargetOrder/CustomerTargetOrderDetail'
import CustomerTargetOrdersCanceled from '../scenes/customer/TargetOrder/CustomerTargetOrdersCanceled'
import SalesmanTargetOrderDetail from '../scenes/salesman/TargetOrder/SalesmanTargetOrderDetail'
import TraderTargetOrderDetail from '../scenes/trader/TargetOrder/TraderTargetOrderDetail'
import TraderTargetOrdersCanceled from '../scenes/trader/TargetOrder/TraderTargetOrdersCanceled'
import SalesmanTargetOrdersCanceled from '../scenes/salesman/TargetOrder/SalesmanTargetOrdersCanceled'
import SuperAdminSettingsMerchants from '../scenes/superadmin/Settings/Merchants/SuperAdminSettingsMerchants'
import TraderSettingsProfile from '../scenes/trader/Settings/Profile/TraderSettingsProfile'
import ControllerDataOperation from '../scenes/controller/data/operation/ControllerDataOperation'
import ControllerDataCustomerStock from '../scenes/controller/data/customerStock/ControllerDataCustomerStock'
import SuperAdminSettingsMajorationTable from '../scenes/superadmin/Settings/MajorationTable/SuperAdminSettingsMajorationTable'
import SuperAdminPMGSettings from '../scenes/superadmin/Settings/Pmg/SuperAdminPMGSettings'
import ControllerDataTransaction from '../scenes/controller/data/transaction/ControllerDataTransaction'
import UserContext from '../contexts/UserContext'
import StrikeSettingsScene from '../scenes/superadmin/Settings/Strikes/StrikeSettingsScene'
import CustomerPmgSalesList from '../scenes/customer/PMG/SalesList/CustomerPmgSalesList'
import SalesmanPmgSalesList from '../scenes/salesman/PMG/SalesList/SalesmanPmgSalesList'
import TraderPmgClosedSalesList from '../scenes/trader/PMG/SalesList/TraderPmgClosedSalesList'
import SalesmanPmgClosedSalesList from '../scenes/salesman/PMG/SalesList/SalesmanPmgClosedSalesList'
import CustomerPmgClosedSalesList from '../scenes/customer/PMG/SalesList/CustomerPmgClosedSalesList'
import TraderSettingsExportContracts from '../scenes/trader/Settings/ExportContracts/TraderSettingsExportContracts'
import SuperAdminSettingsExportContracts from '../scenes/superadmin/Settings/ExportContracts/SuperAdminSettingsExportContracts'

const LoggedRoutes = () => {
  const { hasRole } = useContext(UserContext)

  return (
    <Switch>
      {hasRole &&
        hasRole('ROLE_TRADER') && [
          <Route key="/home" exact path="/home" component={TraderHome} />,
          <Route
            key="/offers-list"
            exact
            path="/offers-list"
            component={TraderOffersList}
          />,
          <Route
            key="/home/new-offer/step1"
            exact
            path="/home/new-offer/step1"
            component={NewOfferStep1}
          />,
          <Route
            key="/home/new-offer/step2"
            exact
            path="/home/new-offer/step2"
            component={NewOfferStep2}
          />,
          <Route
            key="/home/new-offer/recap"
            exact
            path="/home/new-offer/recap"
            component={NewOfferRecap}
          />,
          <Route
            key="/settings"
            exact
            path="/settings"
            component={TraderSettings}
          />,
          <Route
            key="/settings/campaigns"
            exact
            path="/settings/campaigns"
            component={TraderSettingsCampaign}
          />,
          <Route
            key="/settings/majoration-tables"
            exact
            path="/settings/majoration-tables"
            component={TraderSettingsMajorationTables}
          />,
          <Route
            key="/settings/mail-templates"
            exact
            path="/settings/mail-templates"
            component={TraderSettingsMailTemplates}
          />,
          <Route
            key="/settings/delivery-sites"
            exact
            path="/settings/delivery-sites"
            component={DeliverySitesSettings}
          />,
          <Route
            key="/settings/customers"
            exact
            path="/settings/customers"
            component={TraderSettingsCustomers}
          />,
          <Route
            key="/settings/sales"
            exact
            path="/settings/sales"
            component={SalesSettings}
          />,
          <Route
            key="/settings/sectors"
            exact
            path="/settings/sectors"
            component={SectorSettings}
          />,
          <Route
            key="/settings/products"
            exact
            path="/settings/products"
            component={ProductsSettings}
          />,
          <Route
            key="/settings/storages"
            exact
            path="/settings/storages"
            component={StoragesSettings}
          />,
          <Route
            key="/settings/parities"
            exact
            path="/settings/parities"
            component={TransportParitySettings}
          />,
          <Route
            key="/settings/contributions"
            exact
            path="/settings/contributions"
            component={ContributionsSettings}
          />,
          <Route
            key="/settings/execution-modes"
            exact
            path="/settings/execution-modes"
            component={ExecutionModesSettings}
          />,
          <Route
            key="/settings/users"
            exact
            path="/settings/users"
            component={UsersSettings}
          />,
          <Route
            key="/settings/strike-pmg"
            exact
            path="/settings/strike-pmg"
            component={StrikeSettingsScene}
          />,
          <Route
            key="/customers"
            exact
            path="/customers"
            component={TraderCustomers}
          />,
          <Route
            key="/customers/:id"
            exact
            path="/customers/:id"
            component={TraderCustomerDetails}
          />,
          <Route
            key="/sales/list"
            exact
            path="/sales/list"
            component={TraderSalesList}
          />,
          <Route
            key="/customers/:id/sale"
            exact
            path="/customers/:id/sale"
            component={TraderCustomerSale}
          />,
          <Route
            key="/customers/:id/sale/recap"
            exact
            path="/customers/:id/sale/recap"
            component={TraderCustomerSaleRecap}
          />,
          <Route
            key="/settings/contract-templates"
            exact
            path="/settings/contract-templates"
            component={TraderSettingsContractTemplates}
          />,
          <Route
            key="/settings/exportcontracts"
            exact
            path="/settings/exportcontracts"
            component={TraderSettingsExportContracts}
          />,
          <Route
            key="/target-order/detail"
            path="/target-order/detail/:id([0-9]+)"
            component={TraderTargetOrderDetail}
          />,
          <Route
            key="/target-order/cancel"
            exact
            path="/target-order/cancel"
            component={TraderTargetOrdersCanceled}
          />,
          <Route
            key="/settings/profil"
            exact
            path="/settings/profil"
            component={TraderSettingsProfile}
          />,
          <Route
            key="/sales/pmg-list"
            exact
            path="/sales/pmg-list"
            component={TraderPmgSalesList}
          />,
          <Route
            key="/sales/pmg-list/closed"
            exact
            path="/sales/pmg-list/closed"
            component={TraderPmgClosedSalesList}
          />,
        ]}
      {hasRole &&
        hasRole('ROLE_CUSTOMER') && [
          <Route key="/home" exact path="/home" component={CustomerHome} />,
          <Route
            key="/settings"
            exact
            path="/settings"
            component={CustomerSettings}
          />,
          <Route key="/sale" exact path="/sale" component={CustomerSale} />,
          <Route
            key="/sale/recap"
            exact
            path="/sale/recap"
            component={CustomerSaleRecap}
          />,
          <Route
            key="/sales/list"
            exact
            path="/sales/list"
            component={CustomerSalesList}
          />,
          <Route
            key="/target-order/detail"
            exact
            path="/target-order/detail/:id([0-9]+)"
            component={CustomerTargetOrderDetail}
          />,
          <Route
            key="/target-order/cancel"
            exact
            path="/target-order/cancel"
            component={CustomerTargetOrdersCanceled}
          />,
          <Route
            key="/sales/pmg-list"
            exact
            path="/sales/pmg-list"
            component={CustomerPmgSalesList}
          />,
          <Route
            key="/sales/pmg-list/closed"
            exact
            path="/sales/pmg-list/closed"
            component={CustomerPmgClosedSalesList}
          />,
        ]}
      {hasRole &&
        hasRole('ROLE_SALESMAN') && [
          <Route
            key="/customers"
            exact
            path="/customers"
            component={SalesmanCustomers}
          />,
          <Route
            key="/customers/:id"
            exact
            path="/customers/:id"
            component={SalesmanCustomerDetails}
          />,
          <Route
            key="/sales/list"
            exact
            path="/sales/list"
            component={SalesmanSalesList}
          />,
          <Route
            key="/customers/:id/sale"
            exact
            path="/customers/:id/sale"
            component={SalesmanCustomerSale}
          />,
          <Route
            key="/customers/:id/sale/recap"
            exact
            path="/customers/:id/sale/recap"
            component={SalesmanCustomerSaleRecap}
          />,
          <Route
            key="/target-order/detail"
            exact
            path="/target-order/detail/:id([0-9]+)"
            component={SalesmanTargetOrderDetail}
          />,
          <Route
            key="/target-order/cancel"
            exact
            path="/target-order/cancel"
            component={SalesmanTargetOrdersCanceled}
          />,
          <Route
            key="/sales/pmg-list"
            exact
            path="/sales/pmg-list"
            component={SalesmanPmgSalesList}
          />,
          <Route
            key="/sales/pmg-list/closed"
            exact
            path="/sales/pmg-list/closed"
            component={SalesmanPmgClosedSalesList}
          />,
          <Redirect key="/home" exact from="/home" to="/customers" />,
        ]}
      {hasRole &&
        hasRole('ROLE_SUPERADMIN') && [
          <Route
            key="/settings"
            exact
            path="/settings"
            component={SuperAdminSettings}
          />,
          <Route
            key="/settings/market"
            exact
            path="/settings/market"
            component={MarketSettings}
          />,
          <Route
            key="/settings/advices"
            exact
            path="/settings/advices"
            component={AdvicesSettings}
          />,
          <Route
            key="/settings/contract-templates"
            exact
            path="/settings/contract-templates"
            component={SuperAdminSettingsContractTemplates}
          />,
          <Route
            key="/settings/mail-templates"
            exact
            path="/settings/mail-templates"
            component={SuperAdminSettingsMailtemplate}
          />,
          <Route
            key="/settings/executionmodelabels"
            exact
            path="/settings/executionmodelabels"
            component={SuperAdminSettingsExecutionModeLabels}
          />,
          <Route
            key="/settings/merchants"
            exact
            path="/settings/merchants"
            component={SuperAdminSettingsMerchants}
          />,
          <Route
            key="/settings/majoration-tables"
            exact
            path="/settings/majoration-tables"
            component={SuperAdminSettingsMajorationTable}
          />,
          <Redirect key="/home" exact from="/home" to="/settings" />,
          <Route
            key="/settings/strike-pmg"
            exact
            path="/settings/strike-pmg"
            component={StrikeSettingsScene}
          />,
          <Route
            key="/settings/pmg"
            exact
            path="/settings/pmg"
            component={SuperAdminPMGSettings}
          />,
          <Route
            key="/settings/exportcontracts"
            exact
            path="/settings/exportcontracts"
            component={SuperAdminSettingsExportContracts}
          />,
          <Redirect key="/home" exact from="/home" to="/settings" />,
        ]}
      {hasRole &&
        hasRole('ROLE_CONTROLLER') && [
          <Route
            key="/home"
            exact
            path="/home"
            component={ControllerDataTransaction}
          />,
          <Route
            key="/data/transaction"
            exact
            path="/data/transaction"
            component={ControllerDataTransaction}
          />,
          <Route
            key="/data/market-operation"
            exact
            path="/data/market-operation"
            component={ControllerDataOperation}
          />,
          <Route
            key="/data/inventory"
            exact
            path="/data/inventory"
            component={ControllerDataCustomerStock}
          />,
        ]}
      <Route exact path="/logout" component={Logout} />
      <Redirect exact from="/" to="/home" />
      <Redirect exact from="/reset-password" to="/home" />
      <Redirect exact from="/forgot-password" to="/home" />
      <Route component={NotFound} />
    </Switch>
  )
}

export default LoggedRoutes
